import Axios from 'axios';
import server from 'util/Server';

const host  = `${server.host}${server.puertoSap}`
const host_integration_sap  = `${server.host}${server.puerto_integracion_sap}`

export const obtenerFechasConNovedades = (empresas_id:number) =>{
    return Axios.get(`${host}/api/v1/news/${empresas_id}`,{ headers: { Authorization: server.obtenerToken() } })
}

export const getNovedades = (queryParams:{startDate:string,empresaId:number}) =>{
    return Axios.get(`${host}/api/v1/news`,{params:queryParams, headers: { Authorization: server.obtenerToken() }})
}

export const getDiaConsolidado = (queryParams:{startDate:string,empresaId:number}) =>{
    return Axios.get(`${host}/api/v1/consolidator/allBusiness`,{params:queryParams, headers: { Authorization: server.obtenerToken() }})
}

export const getEmpresasByNegocio = (negocio:number) =>{
    return Axios.get(`${server.host}${server.puertoCentury}/api/negocio/getempresa/${negocio}`,{ headers: { Authorization: server.obtenerToken() }})
}

export const obtenerDiferenciasLecturaService = async (queryParams:{startDate:string,empresaId:number}) =>{
    return Axios.get(`${host}/api/v1/consolidator/readingsdifference`,{params:queryParams, headers: { Authorization: server.obtenerToken() }})
}
export const obtenerDiasConsolidado = async (queryParams:{fechaCierre:string,empresaId:number}) =>{
    return Axios.get(`${host_integration_sap}/api/v1/sap/clousingDay`,{params:queryParams, headers: { Authorization: server.obtenerToken() }})
}

export const crearCierreDia = async (body:{fechaCierre:string,empresaId:number}) =>{
    return Axios.post(`${host_integration_sap}/api/v1/sap/clousingDay/create`,body,{ headers: { Authorization: server.obtenerToken() }})
}