import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'

import Loader from 'components/Loader'

const CategoriasSubCategorias = lazy(async () => import('./categoriasSubCategorias/CategoriasSubCategorias'));
const Productos = lazy(async () => import('./productos/'));
const MediosPagos = lazy(async () =>import('../Configuracion/GestionMediosPagos'))

export default function Catalogo({ match }: any) {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
        <Route  path={`${match.url}/categoria_subcategoria`} component={CategoriasSubCategorias}/>
        <Route  path={`${match.url}/productos`} component={Productos}/>
        <Route  path={`${match.url}/gestion_medios_pagos`} component={MediosPagos}/>
        </Suspense>
      </Switch>
    </div>
  )
}

